import * as bootstrap from 'bootstrap'
import Splide from '@splidejs/splide'

///
// Dropdown navigation
///

const myDropdown = document.getElementById('navbarDropdown')

myDropdown.addEventListener('show.bs.dropdown', function () {
  const element = document.getElementById('pageHeader')
  element.classList.add('open')
})

myDropdown.addEventListener('hide.bs.dropdown', function () {
  const element = document.getElementById('pageHeader')
  element.classList.remove('open')
})

///
// Program teaser slider
///

const teaserSplides = document.querySelectorAll('.splide-teaser')
if (teaserSplides.length) {
  for (let i = 0; i < teaserSplides.length; i++) {
    let splideElement
    splideElement = teaserSplides[i]
    const splideDefaultOptions =
      {
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: false,
        perPage: 2,
        perMove: 1,
        gap: '1.5em',
        type: 'slide',
        pagination: true,
        keyboard: false,
        slideFocus: false,
        mediaQuery: 'min',
        breakpoints: {
          576: {
            perPage: 2
          },
          768: {
            perPage: 3
          },
          992: {
            perPage: 4
          },
          1200: {
            perPage: 4
          },
          1400: {
            perPage: 5
          }
        }
      }
    const splide = new Splide(splideElement, splideDefaultOptions)

    splide.mount()
  }
}

///
// Event teaser slider
///

const eventSplides = document.querySelectorAll('.splide-events')
if (eventSplides.length) {
  for (let i = 0; i < eventSplides.length; i++) {
    var splideElement
    splideElement = eventSplides[i]
    const splideDefaultOptions =
      {
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: false,
        perPage: 1,
        perMove: 1,
        gap: '1.5em',
        type: 'slide',
        pagination: true,
        keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: false, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
        mediaQuery: 'min',
        breakpoints: {
          576: {
            perPage: 1
          },
          768: {
            perPage: 2
          },
          992: {
            perPage: 2
          },
          1200: {
            perPage: 3
          },
          1400: {
            perPage: 3
          }
        }
      }
    const splide = new Splide(splideElement, splideDefaultOptions)

    splide.mount()
  }
}

///
// Hero banner slider
///

let splide
let previousButton, nextButton
let rotationButton, pauseContainer, resumeContainer

document.addEventListener('DOMContentLoaded', function () {
  previousButton = document.querySelector('.carousel .previous-button')
  nextButton = document.querySelector('.carousel .next-button')

  rotationButton = document.querySelector('.carousel .rotation-button')
  pauseContainer = document.querySelector('.carousel .rotation-button .pause-container')
  resumeContainer = document.querySelector('.carousel .rotation-button .resume-container')

  splide = new Splide('.splide-hero', {
    autoplay: true,
    interval: 5300,
    pauseOnHover: false,
    loop: true,
    arrows: false,
    perPage: 1,
    type: 'slide',
    pagination: false,
    keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
    slideFocus: false // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
  }).mount()

  // To prevent animation issues, let's make every slide visible before a transition happens. Splide will then automatically remove the `.is-visible` class from non-visible slides once the transition is finished.
  splide.on('move', function () {
    const slides = document.querySelectorAll('.splide-hero .splide__slide')

    slides.forEach(function (slide) {
      slide.classList.add('is-visible')
    })
  })

  // Go to the previous slide and stop autoplay when the Previous button is activated
  previousButton.addEventListener('click', function (e) {
    disableAutoplay()
    splide.go('<')
  })

  // Go to the next slide and stop autoplay when the Next button is activated
  nextButton.addEventListener('click', function (e) {
    disableAutoplay()
    splide.go('>')
  })

  // Toggle autoplay when the pause/resume button is activated
  rotationButton.addEventListener('click', function (e) {
    toggleAutoplay()
  })

  /**
   Disable or enable built-in Splide Slider autoplay
   */
  function toggleAutoplay () {
    const autoplayEnabled = splide.options.autoplay

    if (autoplayEnabled) {
      disableAutoplay()
    } else {
      enableAutoplay()
    }
  }

  function disableAutoplay () {
    // Disable autoplay using the Splide API
    splide.Components.Autoplay.pause()
    splide.options = { autoplay: false }

    // Switch the rotation button icon to "resume"
    pauseContainer.classList.remove('is-visible')
    resumeContainer.classList.add('is-visible')
  }

  function enableAutoplay () {
    // Disable autoplay using the Splide API
    splide.Components.Autoplay.play()
    splide.options = { autoplay: true }

    // Switch the rotation button icon to "pause"
    pauseContainer.classList.add('is-visible')
    resumeContainer.classList.remove('is-visible')
  }
})

///
// Gallery slider
///

const gallerySplides = document.querySelectorAll('.splide-gallery')
if (gallerySplides.length) {
  for (let i = 0; i < gallerySplides.length; i++) {
    var splideElement
    splideElement = gallerySplides[i]

    const SliderModalID = splideElement.getAttribute('data-modalid')
    const SliderModalElement = document.querySelectorAll('.splide-modal[data-modalid=' + SliderModalID + ']')

    const splideDefaultOptions =
      {
        type: 'loop',
        focus: 'center',
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: false,
        perPage: 2,
        perMove: 1,
        gap: '1.5em',
        pagination: true,
        isNavigation: true,
        keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: false, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
        mediaQuery: 'min',
        breakpoints: {
          1: {
            perPage: 1,
            padding: '3em',
            height: '15rem',
            autoWidth: true
          },
          576: {
            perPage: 2,
            padding: '3em',
            autoWidth: true,
            height: '15rem'
          },
          768: {
            perPage: 2,
            height: '15rem',
            autoWidth: true
          },
          992: {
            perPage: 3,
            autoWidth: true,
            height: '15rem'
          },
          1200: {
            perPage: 1,
            autoWidth: true,
            height: '30rem'
          },
          1400: {
            perPage: 1,
            autoWidth: true,
            height: '30rem'
          }
        }
      }
    const splide = new Splide(splideElement, splideDefaultOptions)

    // Modal

    const splideDefaultOptionsModal =
      {
        type: 'loop',
        focus: 'center',
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: false,
        perPage: 2,
        perMove: 1,
        gap: '1.5em',
        pagination: true,
        isNavigation: true,
        keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: false, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
        mediaQuery: 'min',
        breakpoints: {
          1: {
            perPage: 1,
            padding: '3em'
          },
          576: {
            perPage: 2
          },
          768: {
            perPage: 2
          },
          992: {
            perPage: 3
          },
          1200: {
            perPage: 1
          },
          1400: {
            perPage: 1,
            autoWidth: true
          }
        }
      }
    const splideModal = new Splide(SliderModalElement[0], splideDefaultOptionsModal)

    splide.sync(splideModal)
    splide.mount()
    splideModal.mount()

    const myModalEl = document.getElementsByClassName('modal--gallery')
    for (let i = 0; i < myModalEl.length; i++) {
      myModalEl[i].addEventListener('shown.bs.modal', function (event) {
        splideModal.refresh()
      })
    }
  }
}

///
// Gallery slider
///

const modalSplides = document.querySelectorAll('.splide-modalsss')
if (modalSplides.length) {
  for (let i = 0; i < modalSplides.length; i++) {
    var splideElement
    splideElement = modalSplides[i]
    const splideDefaultOptions =
      {
        type: 'loop',
        focus: 'center',
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: false,
        perPage: 2,
        perMove: 1,
        gap: '1.5em',
        pagination: true,
        keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: false, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
        mediaQuery: 'min',
        breakpoints: {
          1: {
            perPage: 1,
            padding: '3em'
          },
          576: {
            perPage: 2
          },
          768: {
            perPage: 2
          },
          992: {
            perPage: 3
          },
          1200: {
            perPage: 1
          },
          1400: {
            perPage: 1,
            autoWidth: true
          }
        }
      }
    const splide = new Splide(splideElement, splideDefaultOptions)

    splide.mount()
  }
}
